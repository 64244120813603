<template>
  <div class="navbar-container d-flex content align-items-center">
    <b-navbar-nav class="nav align-items-center col-2">
      <img src="../../../../public/logo.png" class="img-fluid logo" alt="" />
    </b-navbar-nav>
    <b-navbar-nav class="nav align-items-center mx-auto">
      <h1 class="font-weight-bolder">
        INDOSAT OOREDOO HUTCHISON COMMAND CENTER
      </h1>
    </b-navbar-nav>
    <b-navbar-nav class="nav align-items-center col-2">
      <h2
        style="
          position: absolute;
          font-size: 28px;
          right: 0.5rem;
          cursor: pointer;
        "
        @click="setDate()"
      >
        <i class="bi bi-calendar-fill"></i>
        <span
          id="filter-tanggal-text"
          style="
            position: absolute;
            right: 0px;
            left: 0px;
            top: 15px;
            color: black;
            font-size: 12px;
            font-weight: bolder;
          "
          ><center>{{ $store.getters.title }}</center></span
        >
      </h2>
    </b-navbar-nav>
    <set_date_modal :data="modal_data" />
  </div>
</template>
  
<script>
import { BNavbarNav } from "bootstrap-vue";
import set_date_modal from "../Usable/SetDateModal";

export default {
  components: {
    BNavbarNav,
    set_date_modal,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modal_data: {
        title: "Periode Data",
        id: "set_date",
      },
    };
  },
  methods: {
    setDate() {
      this.$emit
      this.$bvModal.show("set_date");
    },
  },
};
</script>
<style>
.logo {
  height: 4.5rem !important;
}
</style>
  