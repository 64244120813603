<template>
  <vue-apex-charts
    :height="line_height / 9"
    :options="config"
    :series="data.series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "LineAreaChart",
  components: {
    VueApexCharts,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      line_height: this.$store.getters.height,
      config: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        colors: this.data.colors,
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.2,
            type: "vertical",
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
          categories: this.data.categories,
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
              left: 0,
              right: 0,
            },
          },
        ],
        tooltip: {
          y: { show: true },
        },
      },
    };
  },
};
</script>
