<template>
  <div>
    <div class="row">
      <div class="col-4 box-content">
        <b-card class="h-100">
          <center>
            <b-card-title>
              Social Media Corporate Number of Followers
            </b-card-title>
          </center>
          <span v-if="is_follower_loading">
            <center style="margin-top: 10rem">
              <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
              <h5>Loading...</h5>
            </center>
          </span>
          <line_chart v-else :data="follower_chart_data" />
        </b-card>
      </div>
      <div class="col-4 box-content">
        <b-card class="h-100">
          <center>
            <b-card-title>Daily Number of Post IOH Corporate</b-card-title>
          </center>
          <span v-if="is_source_loading">
            <center style="margin-top: 10rem">
              <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
              <h5>Loading...</h5>
            </center>
          </span>
          <line_chart v-else :data="source_chart_data" />
        </b-card>
      </div>
      <div class="col-4 box-content">
        <div class="row mb-2">
          <div class="col-12" style="height: 20vh">
            <b-card no-body class="h-100">
              <div class="mx-1 mt-1">
                <h5>Twitter Trending</h5>
                <small>{{ terms }}</small>
                <div v-if="!is_twitter_loading" class="d-flex">
                  <h5>{{ thousandSeparator(total_twitter) }}</h5>
                  <small class="ml-1">Tweets</small>
                </div>
              </div>
              <div class="d-block h-100">
                <span v-if="is_twitter_loading">
                  <center class="mt-1 mb-5">
                    <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
                    <h5>Loading...</h5>
                  </center>
                </span>
                <line_area_chart v-else :data="twitter_chart_data" />
              </div>
            </b-card>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12" style="height: 20vh">
            <b-card no-body class="h-100">
              <div class="mx-1 mt-1">
                <h5>Posting Negatif</h5>
                <small>Pemberitaan {{ $store.getters.range_time }}</small>
                <div v-if="!is_this_periode_loading" class="d-flex">
                  <h5>{{ thousandSeparator(total_periode) }}</h5>
                  <small
                    class="ml-1"
                    :class="
                      persentase_periode < 0 ? 'text-success' : 'text-danger'
                    "
                  >
                    <span
                      v-if="persentase_periode > 0"
                      style="margin-right: -2px"
                      >+</span
                    >
                    {{ persentase_periode }} %</small
                  >
                </div>
              </div>
              <div>
                <span v-if="is_this_periode_loading">
                  <center class="mt-1 mb-5">
                    <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
                    <h5>Loading...</h5>
                  </center>
                </span>
                <line_area_chart v-else :data="periode_chart_data" />
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 2rem">
      <div class="col-4 box-content">
        <b-card class="h-100">
          <center>
            <b-card-title>Social Media Corporate Engagement</b-card-title>
          </center>
          <span v-if="is_engagement_loading">
            <center style="margin-top: 10rem">
              <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
              <h5>Loading...</h5>
            </center>
          </span>
          <line_chart v-else :data="engagement_chart_data" />
        </b-card>
      </div>
      <div class="col-4 box-content">
        <b-card class="h-100">
          <center>
            <b-card-title>IOH and Competitor - Recap Sentiment</b-card-title>
          </center>
          <span v-if="is_topic_loading">
            <center style="margin-top: 10rem">
              <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
              <h5>Loading...</h5>
            </center>
          </span>
          <bar_chart v-else :data="topic_chart_data" />
        </b-card>
      </div>
      <div class="col-4 box-content">
        <b-card class="h-100">
          <center><b-card-title>Word Cloud</b-card-title></center>
          <span v-if="is_wordcloud_loading">
            <center style="margin-top: 10rem">
              <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
              <h5>Loading...</h5>
            </center>
          </span>
          <word_cloud v-else :word="word" style="height: 85% !important" />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardHeader,
  BSpinner,
  BAlert,
} from "bootstrap-vue";
import line_chart from "../Usable/LineChart";
import line_area_chart from "../Usable/LineAreaChart";
import bar_chart from "../Usable/HorizontalBarChart";
import word_cloud from "../Usable/WordCloud";

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BSpinner,
    BAlert,
    line_chart,
    line_area_chart,
    bar_chart,
    word_cloud,
  },

  data() {
    return {
      filter: {
        start_date: "",
        end_date: "",
        title: "",
        range_time: "",
      },
      topic_chart_data: {
        colors: ["#28c76f", "#ea5455", "#7367f0"],
        categories: null,
        series: null,
      },
      source_chart_data: {
        colors: [
          "#467fff",
          "#00acee",
          "#ea5455",
          "#ff0000",
          "#ff9f43",
          "#4b4b4b",
        ],
        categories: null,
        series: null,
      },
      follower_chart_data: {
        colors: [
          "#467fff",
          "#00acee",
          "#ea5455",
          "#ff0000",
          "#000000",
          "#0a66c2",
        ],
        categories: null,
        series: null,
      },
      engagement_chart_data: {
        colors: [
          "#467fff",
          "#00acee",
          "#ea5455",
          "#ff0000",
          "#000000",
          "#0a66c2",
        ],
        categories: null,
        series: null,
      },
      bar_chart_data: {
        colors: ["#28c76f", "#7367f0", "#ea5455"],
        categories: null,
        series: null,
      },
      periode_chart_data: {
        colors: ["#ff9f43", "#ea5455"],
        categories: null,
        series: [
          {
            type: "area",
            name: null,
            data: null,
          },
          {
            type: "area",
            name: null,
            data: null,
          },
        ],
      },
      twitter_chart_data: {
        colors: ["#467FFF"],
        categories: [0],
        series: [
          {
            type: "area",
            name: "Twitter",
            data: [0],
          },
        ],
      },
      word: null,
      total_periode: 0,
      total_twitter: 0,
      terms: "",
      persentase_periode: 0,
      is_source_loading: true,
      is_topic_loading: true,
      is_wordcloud_loading: true,
      is_follower_loading: true,
      is_engagement_loading: true,
      is_this_periode_loading: true,
      is_twitter_loading: true,
    };
  },
  methods: {
    thousandSeparator(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getSources() {
      this.is_source_loading = true;
      let api = process.env.VUE_APP_API_URL + "trends/sources";
      let token = process.env.VUE_APP_TOKEN;
      this.$http.defaults.headers.common = { Authorization: `Bearer ${token}` };
      let query = {
        start_date: this.$store.getters.start_date,
        end_date: this.$store.getters.end_date,
      };
      this.$http
        .get(api, { params: query })
        .then((response) => {
          this.source_chart_data.categories = response.data.data.categories;
          this.source_chart_data.series = response.data.data.series;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.is_source_loading = false;
        });
    },
    getTopic() {
      this.is_topic_loading = true;
      let api = process.env.VUE_APP_API_URL + "trends/topics";
      let token = process.env.VUE_APP_TOKEN;
      this.$http.defaults.headers.common = { Authorization: `Bearer ${token}` };
      let query = {
        start_date: this.$store.getters.start_date,
        end_date: this.$store.getters.end_date,
      };
      this.$http
        .get(api, { params: query })
        .then((response) => {
          this.topic_chart_data.categories = response.data.data.categories;
          this.topic_chart_data.series = response.data.data.series;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.is_topic_loading = false;
        });
    },
    getWordCloud() {
      this.is_wordcloud_loading = true;
      let api = process.env.VUE_APP_API_URL + "trends/wordcloud";
      let token = process.env.VUE_APP_TOKEN;
      this.$http.defaults.headers.common = { Authorization: `Bearer ${token}` };
      let query = {
        start_date: this.$store.getters.start_date,
        end_date: this.$store.getters.end_date,
      };
      this.$http
        .get(api, { params: query })
        .then((response) => {
          this.word = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.is_wordcloud_loading = false;
        });
    },
    getFollowers() {
      this.is_follower_loading = true;
      let api = process.env.VUE_APP_API_URL + "trends/account";
      let token = process.env.VUE_APP_TOKEN;
      this.$http.defaults.headers.common = { Authorization: `Bearer ${token}` };
      let query = {
        start_date: this.$store.getters.start_date,
        end_date: this.$store.getters.end_date,
        field: "followers",
      };
      this.$http
        .get(api, { params: query })
        .then((response) => {
          this.follower_chart_data.categories = response.data.data.categories;
          this.follower_chart_data.series = response.data.data.series;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.is_follower_loading = false;
        });
    },
    getEngagements() {
      this.is_engagement_loading = true;
      let api = process.env.VUE_APP_API_URL + "trends/account";
      let token = process.env.VUE_APP_TOKEN;
      this.$http.defaults.headers.common = { Authorization: `Bearer ${token}` };
      let query = {
        start_date: this.$store.getters.start_date,
        end_date: this.$store.getters.end_date,
        field: "engagement",
      };
      this.$http
        .get(api, { params: query })
        .then((response) => {
          this.engagement_chart_data.categories = response.data.data.categories;
          this.engagement_chart_data.series = response.data.data.series;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.is_engagement_loading = false;
        });
    },
    getTwitterTrending() {
      this.is_twitter_empty = false;
      this.is_twitter_loading = true;
      let api = process.env.VUE_APP_API_URL + "trends/twitter";
      let token = process.env.VUE_APP_TOKEN;
      this.$http.defaults.headers.common = { Authorization: `Bearer ${token}` };
      let query = {
        start_date: this.$store.getters.start_date,
        end_date: this.$store.getters.end_date,
      };
      this.$http
        .get(api, { params: query })
        .then((response) => {
          if (response.data !== "") {
            let arr = response.data.data.terms;
            let date = new Date(response.data.data._id)
              .toLocaleString("id", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              })
              .replaceAll(".", ":");
            this.terms = arr.join(", ") + " di Twitter Trending pada " + date;
            this.twitter_chart_data.categories = response.data.trend.categories;
            this.twitter_chart_data.series[0].data = response.data.trend.series;
            this.total_twitter = response.data.data.tweet_volume;
          } else {
            let c = [0];
            let s = [0];
            this.twitter_chart_data.categories = c;
            this.twitter_chart_data.series[0].data = s;
            this.total_twitter = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.is_twitter_loading = false;
        });
    },
    getGrowthTrending() {
      this.is_this_periode_loading = true;
      let api = process.env.VUE_APP_API_URL + "trends/growth";
      let token = process.env.VUE_APP_TOKEN;
      this.$http.defaults.headers.common = { Authorization: `Bearer ${token}` };
      let query = {
        start_date: this.$store.getters.start_date,
        end_date: this.$store.getters.end_date,
      };
      this.$http
        .get(api, { params: query })
        .then((response) => {
          this.periode_chart_data.categories =
            response.data.this_periode.categories;

          this.periode_chart_data.series[0].data =
            response.data.last_periode.series;
          this.periode_chart_data.series[0].name = "Last Periode";

          this.periode_chart_data.series[1].data =
            response.data.this_periode.series;
          this.periode_chart_data.series[1].name = "This Periode";

          this.total_periode =
            response.data.last_periode.total + response.data.this_periode.total;

          this.persentase_periode = response.data.growth.toFixed(2);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.is_this_periode_loading = false;
        });
    },
    setOneWeekAgo() {
      this.filter.start_date = new Date();
      this.filter.end_date = new Date();
      this.filter.start_date.setDate(this.filter.start_date.getDate() - 7);
      this.filter.title = "7D";
      this.filter.range_time = "7 Hari Lalu";
      this.$store.commit("SET_FILTER_DATE", this.filter);
    },
  },
  watch: {
    "$store.getters.start_date"() {
      this.getTwitterTrending();
      this.getSources();
      this.getTopic();
      this.getWordCloud();
      this.getFollowers();
      this.getEngagements();
      this.getGrowthTrending();
    },
    "$store.getters.end_date"() {
      this.getTwitterTrending();
      this.getSources();
      this.getTopic();
      this.getWordCloud();
      this.getFollowers();
      this.getEngagements();
      this.getGrowthTrending();
    },
  },
  created() {
    this.setOneWeekAgo();
  },
  mounted() {
    setInterval(() => {
      this.getTwitterTrending();
      this.getSources();
      this.getTopic();
      this.getWordCloud();
      this.getFollowers();
      this.getEngagements();
      this.getGrowthTrending();
    }, 300000);
    window.onresize = () => {
      this.$store.commit("SET_CURRENT_HEIGHT", window.innerHeight);
      this.getTwitterTrending();
      this.getSources();
      this.getTopic();
      this.getWordCloud();
      this.getFollowers();
      this.getEngagements();
      this.getGrowthTrending();
    };
    this.getSources();
    this.getTopic();
    this.getWordCloud();
    this.getFollowers();
    this.getEngagements();
    this.getTwitterTrending();
    this.getGrowthTrending();
  },
};
</script>

<style>
.box-content {
  height: 42vh !important;
}
</style>
