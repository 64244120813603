import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
// Modules
import app from "./app";
import appConfig from "./app-config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    start_date: "",
    end_date: "",
    title: "7D",
    range_time: "7 Hari Lalu",
    height: window.innerHeight,
  },
  getters: {
    start_date: (state) => state.start_date,
    end_date: (state) => state.end_date,
    title: (state) => state.title,
    range_time: (state) => state.range_time,
    height: (state) => state.height,
  },
  mutations: {
    SET_FILTER_DATE(state, filter) {
      let start = filter.start_date;
      let sd = String(start.getDate()).padStart(2, "0");
      let sm = String(start.getMonth() + 1).padStart(2, "0");
      let sy = start.getFullYear();

      let end = filter.end_date;
      let ed = String(end.getDate()).padStart(2, "0");
      let em = String(end.getMonth() + 1).padStart(2, "0");
      let ey = end.getFullYear();

      state.start_date = sy + "-" + sm + "-" + sd + " 00:00:00";
      state.end_date = ey + "-" + em + "-" + ed + " 23:59:59";

      state.title = filter.title;
      state.range_time = filter.range_time;
    },
    SET_CURRENT_HEIGHT(state, curr_height) {
      state.height = curr_height;
    },
  },
  modules: {
    app,
    appConfig,
  },
  strict: process.env.DEV,
});
