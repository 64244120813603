<template>
  <div class="container-fluid px-2">
    <div style="padding-bottom: 10px"></div>
    <b-navbar
      class="header-navbar navbar navbar-shadow align-items-center rounded mb-2"
    >
      <Header />
    </b-navbar>
    <FirstPage />
  </div>
</template>
<script>
import Header from "../Components/Header/Index.vue";
import FirstPage from "../Components/Content/FirstPage.vue";
import { BNavbar } from "bootstrap-vue";

export default {
  components: { Header, FirstPage, BNavbar },
};
</script>