<template>
  <vue-apex-charts
    type="line"
    :height="(line_height/3)"
    :options="config"
    :series="data.series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "LineChart",
  components: {
    VueApexCharts,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      line_height:this.$store.getters.height,
      config: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: "line",
          offsetX: -10,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 600,
          horizontalAlign: "center",
          markers: {
            radius: 12,
          },
          labels: {
            colors: ["#A9A9A9"],
          },
        },
        colors: this.data.colors,
        fill: {
          type: "solid",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: this.data.colors,
            shadeIntensity: 1,
            type: "horizontal",
          },
        },
        markers: {
          size: 0,
        },
        xaxis: {
          tickAmount: 12,
          tickPlacement:'between',
          labels: {
            show: true,
            rotate: -25,
            rotateAlways: true,
            hideOverlappingLabels: false,
            style: {
              colors: "#A9A9A9",
              fontSize: "10px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
            offsetX: 0,
          },
          categories: this.data.categories,
        },
        yaxis: {
          labels: {
            style: {
              colors: "#A9A9A9",
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(0)}k` : val;
            },
          },
        },
        grid: {
          borderColor: '#90A4AE',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
    };
  },
};
</script>
