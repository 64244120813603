<template>
  <div id="app" class="h-100 dark-layout">
    <Main />
  </div>
</template>

<script>
import Main from "./views/Main/index.vue";
import { $themeConfig } from "@themeConfig";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: { Main },
  beforeCreate() {
    const { isRTL } = $themeConfig;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    if (skin.value === "dark") document.body.classList.add("dark-layout");
    return {
      skinClasses,
    };
  },
};
</script>
<style>
.dark-layout * .apexcharts-tooltip {
  color: #202940 !important;
  font-weight: bolder !important;
}
.clickable {
  cursor: pointer !important;
}
</style>
