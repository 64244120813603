<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    @ok="validationForm()"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <validation-observer ref="setDate">
      <b-form>
        <!-- DATE SHORTCUT -->
        <div class="d-flex mb-1 mt-1 shortcut">
          <!-- TODAY -->
          <b-badge
            pill
            variant="light-primary"
            class="mr-1 clickable shortcut"
            @click="setToday()"
            >Hari Ini</b-badge
          >
          <b-badge
            pill
            variant="light-primary"
            class="mr-1 clickable shortcut"
            @click="setOneWeekAgo()"
            >7 Hari</b-badge
          >
          <b-badge
            pill
            variant="light-primary"
            class="mr-1 clickable shortcut"
            @click="setOneMonthAgo()"
            >30 Hari</b-badge
          >
          <b-badge
            pill
            variant="light-primary"
            class="mr-1 clickable shortcut"
            @click="setThisMonth()"
            >Bulan Ini</b-badge
          >
          <b-badge
            pill
            variant="light-primary"
            class="mr-1 clickable shortcut"
            @click="setThreeMonthAgo()"
            >3 Bulan</b-badge
          >
          <b-badge
            pill
            variant="light-primary"
            class="mr-1 clickable shortcut"
            @click="setOneYearsAgo()"
            >1 Tahun</b-badge
          >
        </div>

        <!-- START DATE -->
        <div class="row">
          <validation-provider
            #default="{ errors }"
            name="Tanggal Awal"
            rules="required"
            class="col-md-6"
          >
            <label for="sn-tanggal-awal"
              ><strong>Tanggal Awal</strong>
              <span class="text-danger">*</span></label
            >
            <date-picker
              v-model="filter.start_date"
              @change="selected = ''"
              id="sn-tanggal-awal"
              type="date"
              popup-class="rounded mt-1"
              input-class="input bg-transparent form-control w-100 clickable"
              placeholder="Pilih Tanggal Awal"
              format="DD MMM YYYY"
            >
              <!-- CUSTOM CALENDAR ICON -->
              <template #icon-calendar>
                <div>
                  <i
                    class="bi bi-calendar icon"
                    style="opacity: 0.5; margin-right: 6px"
                  ></i>
                </div>
              </template>
            </date-picker>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <!-- END DATE -->
          <validation-provider
            #default="{ errors }"
            name="Tanggal Akhir"
            rules="required"
            class="col-md-6"
          >
            <label for="sn-tanggal-akhir"
              ><strong>Tanggal Akhir </strong>
              <span class="text-danger">*</span></label
            >
            <date-picker
              v-model="filter.end_date"
              @change="selected = ''"
              id="sn-tanggal-akhir"
              type="date"
              popup-class="rounded mt-1"
              input-class="input bg-transparent form-control w-100 clickable"
              placeholder="Pilih Tanggal Akhir"
              format="DD MMM YYYY"
            >
              <!-- CUSTOM CALENDAR ICON -->
              <template #icon-calendar>
                <div>
                  <i
                    class="bi bi-calendar icon"
                    style="opacity: 0.5; margin-right: 6px"
                  ></i>
                </div>
              </template>
            </date-picker>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  name: "SetDateModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BBadge,
    VBModal,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      selected: "",
      filter: {
        start_date: "",
        end_date: "",
        title: "",
        range_time: "",
      },
      required,
    };
  },

  methods: {
    validationForm() {
      this.$refs.setDate.validate().then((success) => {
        if (success) {
          if (this.selected != "") {
            this.filter.title = this.selected;
          } else {
            var value =
              this.filter.end_date.getTime() - this.filter.start_date.getTime();
            this.filter.title =
              Math.round(value / (1000 * 3600 * 24)) + 1 + "D";
            this.filter.range_time =
              Math.round(value / (1000 * 3600 * 24)) + 1 + " Hari Lalu";
          }
          this.$bvModal.hide(this.data.id);
          this.$store.commit("SET_FILTER_DATE", this.filter);
          this.selected = "";
        }
      });
    },
    // SET DATE TO THIS DAY
    setToday() {
      this.filter.start_date = new Date();
      this.filter.end_date = new Date();
      this.selected = "1D";
      this.filter.range_time = "Hari Ini";
    },
    // SET DATE TO 7 DAYS AGO
    setOneWeekAgo() {
      this.filter.start_date = new Date();
      this.filter.end_date = new Date();
      this.filter.start_date.setDate(this.filter.start_date.getDate() - 6);
      this.selected = "7D";
      this.filter.range_time = "7 Hari Lalu";
    },
    // SET DATE TO THIS MONTH
    setThisMonth() {
      this.filter.start_date = new Date();
      this.filter.end_date = new Date();
      this.filter.start_date.setMonth(this.filter.start_date.getMonth());
      this.filter.start_date.setDate(1);
      this.selected = this.filter.end_date.getDate() + "D";
      this.filter.range_time = "Bulan Ini";
    },
    // SET DATE TO ONE MONTH AGO - THIS DAY
    setOneMonthAgo() {
      this.filter.start_date = new Date();
      this.filter.end_date = new Date();
      this.filter.start_date.setMonth(this.filter.start_date.getMonth() - 1);
      this.filter.start_date.setDate(this.filter.start_date.getDate() + 1);
      this.selected = "1M";
      this.filter.range_time = "1 Bulan Lalu";
    },
    // SET DATE TO THREE MONTH AGO - THIS DAY
    setThreeMonthAgo() {
      this.filter.start_date = new Date();
      this.filter.end_date = new Date();
      this.filter.start_date.setMonth(this.filter.start_date.getMonth() - 3);
      this.filter.start_date.setDate(this.filter.start_date.getDate() + 1);
      this.selected = "3M";
      this.filter.range_time = "3 Bulan Lalu";
    },
    // SET DATE TO 1 YEARS AGO - THIS DAY
    setOneYearsAgo() {
      this.filter.start_date = new Date();
      this.filter.end_date = new Date();
      this.filter.start_date.setFullYear(
        this.filter.start_date.getFullYear() - 1
      );
      this.filter.start_date.setDate(this.filter.start_date.getDate() + 1);
      this.selected = "1Y";
      this.filter.range_time = "1 Tahun Lalu";
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>

<style scoped>
.shortcut :hover {
  opacity: 0.8 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
