<template>
  <div>
    <!-- BAR CHART -->
    <vue-apex-charts
      type="bar"
      :height="(bar_height/3)"
      :options="config"
      :series="data.series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "HorizontalBarChart",
  components: {
    VueApexCharts,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      bar_height:this.$store.getters.height,
      config: {
        chart: {
          fontFamily: "Montserrat",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        colors: this.data.colors,
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "70%",
            borderRadius: 5,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          fontWeight: 600,
          horizontalAlign: "center",
          markers: {
            radius: 12,
          },
          labels: {
            colors: ["#A9A9A9"],
          },
        },
        xaxis: {
          categories: this.data.categories,
          labels: {
            show: true,
            style: {
              colors: "#A9A9A9",
              fontSize: "12px",
              fontWeight: 600,
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: "left",
            style: {
              colors: "#A9A9A9",
              fontSize: "12px",
              fontWeight: 600,
            },
            maxWidth: 100,
            offsetX: 0,
          },
        },
      },
    };
  },
};
</script>
